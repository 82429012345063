import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { EAADivider } from "../Components/EAADivider";
import { patchData } from "../feathers";
import { JobOpeningCard } from '../JobOpenings/JobOpeningCard';
import { JobOpeningForm } from '../JobOpenings/JobOpeningForm';
import logger from "../Utils/logger";
import { ENUM_ICONS } from '../Components/Icons';


export const CompanyJobOpenings = ({ company, jobOpenings, numCarousel, onUpdateCompany, hideDivider, readonly }) => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const [jobOpeningId, setJobOpeningIdId] = useState();
	const [jobOpening, setJobOpening] = useState();
	const [hasFormErrors, setHasFormErrors] = useState();


	const handlerEdit = (jobOpeningId) => {
		setJobOpeningIdId(jobOpeningId);
		setDisplayDialog(true)
	}

	const handleCancel = async () => {
		setDisplayDialog(false)
	}

	const handlerDelete = async (jobOpening) => {

	}

	const handleSave = async () => {
		jobOpening.eaaCaseId = (jobOpening.eaaCase ? jobOpening.eaaCase.id : jobOpening.eaaCaseId)
		jobOpening.companyId = company.id
		jobOpening.status = jobOpening.status.alias
		jobOpening.jobType = jobOpening.jobType.alias
		await patchData('job-openings', jobOpening).catch((error) => {
			logger.error('Error while handleSave in CompanyJobOpenings: ' + error.message)
		})
		onUpdateCompany();
		setDisplayDialog(false)
	}

	const renderListEntries = (item) => {
		return <JobOpeningCard key={'jobopening_' + item.id} item={item} onEditHandler={handlerEdit} onDeleteHandler={handlerDelete} readonly={readonly} />
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	return (
		<>
			<div className="flex flex-wrap">
				<EAADivider className={(hideDivider || readonly ? 'hidden ' : '') + 'm-0 my-3 p-1'} icon={ENUM_ICONS.PLUS} label='Neues Stellenprofil' onClickHandler={(e) => { setJobOpeningIdId(null); setDisplayDialog(true) }} />
				<Carousel circular={true} className='flex w-full mt-1 ' contentClassName='flex justify-content-center w-full' showNavigators={jobOpenings.length > 1} showIndicators={false} value={jobOpenings} numVisible={numCarousel} numScroll={numCarousel} itemTemplate={renderListEntries} responsiveOptions={responsiveOptions} />
				<Dialog header={jobOpeningId ? 'Stellenprofil ändern' : 'Neues Stellenprofil hinzufügen'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-job-opening" className='eaa-inputdialog' >
					<JobOpeningForm companyId={company.id} jobOpeningId={jobOpeningId} setJobOpening={setJobOpening} setFormHasErrors={setHasFormErrors} setShowDialog={() => { }} />
					<div className={'card'}>
						<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
							<Button className="flex-order-1 button-cancel" label='Abbrechen' onClick={handleCancel} />
							<Button className="flex-order-0 ml-3 " disabled={hasFormErrors} label={jobOpeningId !== 'new' ? 'speichern' : 'Neu anlegen'} onClick={handleSave} />
						</div>
					</div>
				</Dialog>
			</div>
		</>
	)
}
