import { useEffect, useState } from "react"
import client, { deleteData, findData, getData, patchData, socket } from "../feathers"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format, parseISO } from "date-fns";
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { useStatusFilterUpdate } from '../EAACase/StatusFilterContext';
import { useBreadcrumbsAdd, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { FilterMatchMode } from 'primereact/api';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CompanyForm } from "../Companies/CompanyForm";
import { CompaniesProvider } from "../Persistence/CompaniesContext";
import { ENUM_ICONS } from "../Components/Icons";

const serviceKind = { 'companies': ENUM_ICONS.COMPANY, 'eaa-cases': ENUM_ICONS.EAACASE, 'case-events': ENUM_ICONS.USER, 'job-openings': ENUM_ICONS.JOBPROFILE }

export const Dashboard = () => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const statusFilterUpdate = useStatusFilterUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const addBreadcrumb = useBreadcrumbsAdd();
	const [eaaCases, setEaaCases] = useState([])
	const [remindersList, setRemindersList] = useState([])
	const [reminder, setReminder] = useState([])
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [company, setCompany] = useState();
	const [formHasErrors, setFormHasErrors] = useState();

	useEffect(() => {
		if (configurationsList.CaseStatusList) {
			getData('users', currentUser.id).then((user) => {
				const statusList = configurationsList.CaseStatusList.map((status) => {
					return status.alias === 'INACTIVE' || status.alias === 'CLOSED' ? null : { status, count: 0 }
				}).filter(entry => entry !== null)

				if (user.caseUsers) {
					user.caseUsers.forEach((caseUser) => {
						if (caseUser && caseUser.roleType && caseUser.roleType.alias === 'R') {
							const status = statusList.find(entry => entry.status.alias === caseUser.eaacase.status)
							if (status) {
								status.count = ++status.count;
							}
						}

					})
				}

				setEaaCases(statusList)
			})
		}

	}, [configurationsList])

	useEffect(() => {
		getReminders();

	}, [])

	const getReminders = () => {
		// query mit date -> date: { $and: [{ $gte: startDate }, { $lte: endDate }] },
		const query = {
			active: 1,
			reminded: 0,
			userId: currentUser.id,
			$sort: { date: 1 }
		}
		findData('reminders', query).then(async (reminders) => {

			for (let reminder of reminders) {
				if (reminder.serviceName === 'eaa-cases') {
					reminder.referenceName = await getData(reminder.serviceName, reminder.serviceId).then((eaaCase) => {
						return eaaCase.company.name + " | " + eaaCase.name;
					})

				} else if (reminder.serviceName === 'job-openings') {
					reminder.referenceName = await getData(reminder.serviceName, reminder.serviceId).then((jobOpening) => {
						return jobOpening.company.name + " | " + jobOpening.description;
					})

				} else {
					reminder.referenceName = reminder.name
				}

			}
			setRemindersList(reminders)
		})
	}

	useEffect(() => {

		if (remindersList) {
			const service = client.service('reminders');
			service.removeListener('created')
			service.removeListener('patched')
			service.on('created', getReminders);
			service.on('patched', getReminders);
		}
	}, [remindersList])

	const onClickEAACase = (status) => {
		statusFilterUpdate({ status, responsible: currentUser.lastname + ', ' + currentUser.firstname });
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fallverwaltung', route: ENUM_ROUTES.EAACASES }] })
	}

	const renderEaaCases = () => {
		return eaaCases.map((eaaCase) => {
			return <div key={'eaacase_' + eaaCase.status.alias} onClick={() => { onClickEAACase(eaaCase.status) }} className="flex grid  align-content-start flex-grow-1 h-7rem justify-content-center surface-0 shadow-2 m-2 p-2 border-radius-sm  cursor-pointer">
				<div className="col">
					<div className="flex flex-column">
						<div className="text-lg">{eaaCase.status.display}</div>
						<div className={"mt-2 font-bold text-xl"} style={{ color: eaaCase.status.color }} >{`${eaaCase.count} ${eaaCase.count === 1 ? 'Fall' : 'Fälle'}`}</div>
						<div className="flex text-base w-full justify-content-end">Ansehen</div>
					</div>
				</div>
			</div>
		})
	}

	const handleEditReminder = async (id) => {

		await getData('reminders', id).then((reminder) => {
			setReminder(reminder);
			setDisplayReminderDialog(true);
		})
	}

	const handleSetReminderDone = async (reminder) => {
		reminder.reminded = '1'
		await patchData('reminders', reminder)
	}

	const handleOpenRemindedObject = async (serviceName, id) => {
		const route = serviceName === 'companies' ? ENUM_ROUTES.COMPANIES : (serviceName === 'eaa-cases' ? ENUM_ROUTES.EAACASES : ENUM_ROUTES.JOBPROFILES)
		await getData(serviceName, id).then((result) => {
			addBreadcrumb(`${result.name}`, `${route}/` + id);

		})
	}

	const patchCompany = async () => {
		company.regionId = company.region.id
		await patchData('companies', company, currentUser).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const renderReminderDate = (rowData) => {
		return <div className='flex gap-2'><div className='flex'>{format(parseISO(rowData.date), 'yyyy-MM-dd')}</div></div>
	}

	const renderServiceType = (rowData) => {
		return < i className={'flex ' + serviceKind[rowData.serviceName]} />

	}

	const renderContextMenu = (rowData) => {
		return <div className='open-icon flex justify-content-between h-1rem'>
			<i className={ENUM_ICONS.EYE} onClick={() => handleOpenRemindedObject(rowData.serviceName, rowData.serviceId)} />
			<i className={ENUM_ICONS.PENCIL + 'edit-icon'} onClick={() => handleEditReminder(rowData.id)} />
			<i className={ENUM_ICONS.CHECK + 'check-icon'} onClick={() => handleSetReminderDone(rowData)} />
		</div>
	}

	const [filters, setFilters] = useState({
		'referenceName': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
	});


	return (
		<div className='mx-auto eaa-dataView select-none'>
			{!reminder ? '' :
				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header={reminder.serviceName === 'companies' ? 'Unternehmen' : 'Fall'} name={reminder.name} serviceId={reminder.serviceId} serviceName={reminder.servcieName} userId={reminder.userId} reminderId={reminder.id} />
			}
			<Dialog header='Neues Unternehmen anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-company-details" className='eaa-inputdialog'  >
				<CompaniesProvider><CompanyForm editmode={true} setFormHasErrors={setFormHasErrors} setCompanyForm={setCompany} handleSelectExistingCompany={() => { setDisplayCompanyDialog(false) }} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={() => { }} /></CompaniesProvider>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchCompany} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			<div className="flex justify-content-between flex-wrap w-full pt-2">
				<div className="flex  font-semibold text-xl">
					{`Willkommen zurück ${currentUser.firstname} ${currentUser.lastname}`}
				</div>
				<div className="flex justify-content-end gap-3">
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { addBreadcrumb('Neuen Fall anlegen', `${ENUM_ROUTES.EAACASES}/new`); }} className='flex  text-white' label='Neuen Fall anlegen' />
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { setDisplayCompanyDialog(true) }} className='flex text-white ' label='Neues Unternehmen anlegen' />
				</div>

			</div>
			<div className="card shadow-2 p-4 mt-2 mb-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre Übersicht</div>
				<div className="flex justify-content-center flex-wrap gap-4">
					{renderEaaCases()}
				</div>
			</div>
			{remindersList.length === 0 ? '' : <div className="card shadow-2 p-4 my-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre Wiedervorlagen</div>

				<div style={{ height: 'calc(100vh - 510px)' }}>
					<div className="card hidden md:inline-flex w-full">

						<DataTable emptyMessage='keine Einträge gefunden' filters={filters} className='w-full' value={remindersList} showGridlines stripedRows size="small" filterDisplay="row" responsiveLayout="scroll" dataKey="id" selection={reminder}>
							<Column field='serviceName' className='w-5rem' body={renderServiceType} sortable header="Art"></Column>
							<Column field='referenceName' filter filterPlaceholder="Name suchen" showFilterMenu={false} header="Name"></Column>
							<Column field='date' className='w-11rem' body={renderReminderDate} sortable filter filterPlaceholder="Datum suchen" showFilterMenu={false} header="Datum"></Column>
							<Column field='description' filter filterPlaceholder="Notiz suchen" showFilterMenu={false} header="Notiz"></Column>
							<Column className='w-8rem' body={renderContextMenu}></Column>
						</DataTable>
					</div>
				</div>
			</div>}


		</div>
	)

}