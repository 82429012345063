import { useFormik } from 'formik';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { InputField } from '../Components/InputField';
import { getData, patchData } from "../feathers";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import logger from "../Utils/logger";


export const ContactPersonForm = ({ companyContactPersonId, companyId, handleSave, handleCancel }) => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	//const [filteredContactTypes, setFilteredContactTypes] = useState(null);
	//const [contactPerson, setContactPerson] = useState();
	const [hasErrors, setHasErrors] = useState(false);

	const patchContactPerson = async (contactPerson) => {
		const { id } = contactPerson
		await patchData('contact-persons', contactPerson, currentUser).then(async (contactPerson) => {
			/** 
			 * Wenn die Kontaktperson erfolgreich angelegt ist -> evtl Assoziationen zu Companies oder ContactPersons aktualisieren 
			*/
			const companyContactPerson = { id: companyContactPersonId, companyId, contactPersonId: contactPerson.id, contactType: formik.values.contactType.alias };
			await patchData('company-contact-persons', companyContactPerson)

			if (handleSave) {
				/* Wenn ContactPersonForm eingebettet ist und ein handleSave übergeben wurde, dann über die handleSave das Parent informieren*/
				handleSave(contactPerson.id, companyId)
			}
			handleCancel()
		}).catch((error) => {
			logger.error('Fehler beim Speichern einer Kontaktperson: ' + error.message)
		});
	}

	useEffect(() => {
		if (companyContactPersonId && companyContactPersonId !== null) {
			getData("company-contact-persons", companyContactPersonId).then(async (companyContactPerson) => {
				const { contactPerson } = companyContactPerson;
				contactPerson.contactType = companyContactPerson.contactType
				formik.setValues(contactPerson);
			});
		}
	}, [companyContactPersonId])

	const formik = useFormik({
		initialValues: {
			id: null, active: 1, version: 1, contactType: '', lastname: '', firstname: '', phone: '', mail: '', fax: '', mobile: '', jobDescription: '', companies: [], addresses: []
		},
		validate: (data) => {
			let errors = {};
			if (!data.lastname) {
				errors.lastname = 'Nachname ist ein Pflichtfeld';
			}
			if (!data.contactType) {
				errors.contactType = 'Sie müssen die Kontakt Klassifikation für dieses Unternehmen angeben';
			}
			const hasErrors = Object.keys(errors).length !== 0
			setHasErrors(hasErrors);
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true
	});

	const renderButtons = () => {
		return <div><div className={'card'}>
			<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
				<Button className="flex-order-1 button-cancel" label='Abbrechen' onClick={handleCancel} />
				<Button className="flex-order-0 ml-3 " disabled={hasErrors} label={companyContactPersonId !== 'new' ? 'Speichern' : 'Neu anlegen'} onClick={() => patchContactPerson(formik.values)} />
			</div>
		</div>
		</div>
	}

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
	};

	return (
		<div className='grid'>{!formik ? <div></div> :
			<div className='col'>
				<div className="formgrid grid  m-0 p-0">
					<div className="field col mr-3">
						<label htmlFor="contactType" className={classNames({ 'p-error': isFormFieldValid('contactType') }) + ' block'}>Kontakt Klassifikation</label>
						<Dropdown id="contactType" name="contactType" optionLabel="display" value={formik.values.contactType} options={configurationsList.ContactTypeList} onChange={formik.handleChange} autoFocus className={'bg-white w-full'} />
						{getFormErrorMessage('contactType')}
					</div>
				</div>
				<div className="formgrid grid mr-3">
					<InputField formik={formik} id='lastname' label='Nachname' type='text' />
					<InputField formik={formik} id='firstname' label='Vorname' type='text' />
				</div>
				<div className="formgrid grid mr-3">
					<InputField formik={formik} id='phone' label='Telefon' type='text' />
					<InputField formik={formik} id='fax' label='Fax' type='text' />
				</div>
				<div className="formgrid grid  mr-3">
					<InputField formik={formik} id='mobile' label='Mobile' type='text' className='w-full' />
				</div>
				<div className="formgrid grid mr-3">
					<InputField formik={formik} id='mail' label='E-Mail' type='text' className='w-full' />
				</div>
				<div className="formgrid grid  mr-3">
					<InputField formik={formik} id='jobDescription' label='Job-Beschreibung' type='text' className='w-full' />
				</div>
				{renderButtons()}
			</div>
		}</div>
	)

}